export class DragController {
    constructor(startDragValueProvider, onUpdate, onStartDrag = () => undefined, onStopDrag = () => undefined) {
        this.startDragValueProvider = startDragValueProvider;
        this.onUpdate = onUpdate;
        this.onStartDrag = onStartDrag;
        this.onStopDrag = onStopDrag;
    }
    onPointerDown(e) {
        var _a;
        if (e.pointerType === "touch") {
            return;
        }
        this.usePointer = e.pointerId;
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startValue = this.startDragValueProvider(this.startX, this.startY);
        this.fireUpdate(e.clientX, e.clientY);
        this.moveListener = (event) => {
            if (this.usePointer != event.pointerId) {
                return;
            }
            this.fireUpdate(event.clientX, event.clientY);
        };
        this.upListener = (event) => {
            if (this.usePointer != event.pointerId) {
                return;
            }
            window.removeEventListener("pointermove", this.moveListener);
            window.removeEventListener("pointerup", this.upListener);
            this.onStopDrag();
            // Recover original body user-select property
            if (this.startBodyUserSelect !== "") {
                document.body.style.userSelect = this.startBodyUserSelect;
                document.body.style.webkitUserSelect = this.startBodyUserSelect; // 2022-03-17: prefix was still required for Safari
            }
            else {
                document.body.style.removeProperty("user-select");
                document.body.style.removeProperty("-webkit-user-select"); // 2022-03-17: prefix was still required for Safari
            }
        };
        window.addEventListener("pointermove", this.moveListener);
        window.addEventListener("pointerup", this.upListener);
        this.onStartDrag();
        // Prevent user selection
        this.startBodyUserSelect =
            (_a = document.body.style.userSelect) !== null && _a !== void 0 ? _a :
                // 2022-03-17: prefix was still required for Safari.
                document.body.style.webkitUserSelect;
        document.body.style.userSelect = "none";
        document.body.style.webkitUserSelect = "none"; // 2022-03-17: prefix was still required for Safari
    }
    fireUpdate(x, y) {
        this.onUpdate({
            startValue: this.startValue,
            startX: this.startX,
            startY: this.startY,
            x,
            y,
        });
    }
    onTouchStart(e) {
        if (e.touches.length > 1) {
            return;
        }
        e.preventDefault(); // prevents scrolling on touch devices
        this.touchIdentifier = e.touches[0].identifier;
        this.startX = e.touches[0].clientX;
        this.startY = e.touches[0].clientY;
        this.startValue = this.startDragValueProvider(this.startX, this.startY);
        this.fireUpdate(e.touches[0].clientX, e.touches[0].clientY);
        this.moveListener = (event) => {
            e.preventDefault(); // prevents scrolling on touch devices
            const touch = Array.from(event.touches).filter((t) => t.identifier === this.touchIdentifier)[0];
            if (touch) {
                this.fireUpdate(touch.clientX, touch.clientY);
            }
        };
        this.upListener = (event) => {
            e.preventDefault(); // prevents scrolling on touch devices
            const touch = Array.from(event.changedTouches).filter((t) => t.identifier === this.touchIdentifier)[0];
            if (touch || event.touches.length === 0) {
                window.removeEventListener("touchmove", this.moveListener);
                window.removeEventListener("touchend", this.upListener);
                this.onStopDrag();
            }
        };
        window.addEventListener("touchmove", this.moveListener);
        window.addEventListener("touchend", this.upListener);
        this.onStartDrag();
    }
}
